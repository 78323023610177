import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Home() {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 400,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const imageArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
  return (
    <div className="Home">
      <header className="gempik_header_contents">
        <div className="gempik_header_title">
          <img
            className="gempik_logo"
            src={`${process.env.PUBLIC_URL}/image/Gempik/Gempik_logo.png`}
            alt="gempik_logo"
          />
          <h1>Sing, Dance, Acting</h1>
          <h2>Show your talent in the Gempik contest</h2>
          <div className="gempik_app_link">
            <a
              className="gempik_app_link_apple"
              href="https://apps.apple.com/kr/app/gempik-%EC%A0%AC%ED%94%BD/id6468811885"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/image/Gempik/App_Store_Logo.png`}
                alt="google_play"
              />
            </a>
            <a
              className="gempik_app_link_google"
              href="https://play.google.com/store/apps/details?id=com.gempik.app&pcampaignid=web_share"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/image/Gempik/Google_Play_Logo.png`}
                alt="google_play"
              />
            </a>
          </div>
        </div>
        <div className="gempik_header_image">
          <img
            src={`${process.env.PUBLIC_URL}/image/Gempik/Gempik_phone.png`}
            alt="Gempik_phone"
            className="gempik_phone"
          />
        </div>
      </header>
      <h1 className="gempik_guide">Gempik guide</h1>
      <main className="container gempik_main">
        <Slider {...settings}>
          {imageArray.map((image) => (
            <div className="gempik_image_box">
            <img
              src={`${process.env.PUBLIC_URL}/image/Gempik/gempik_guide_${image}.jpg`}
              className="gempik_image"
              alt={`Gempik_guide${image}`}
            />
          </div>
          ))}
        </Slider>
      </main>
      <div className="gempik_background">
        <img
          src={`${process.env.PUBLIC_URL}/image/Gempik/Gempik_background_2.png`}
          alt="Gempik"
        />
      </div>
    </div>
  );
}
