import "./Navbar.css";

export default function Navbar() {
  return (
    <nav className="gempik_nav">
      <a href="/">
        <img
          src={`${process.env.PUBLIC_URL}/image/Gempik/Gempik_logo_dark.png`}
          alt="Gempik"
        />
      </a>
    </nav>
  );
}
