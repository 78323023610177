import "./Terms.css";

export default function Terms() {
  return (
    <div className="gempik_terms_container">
      <header className="gempik_terms_header">
        <h1>'GemPik' 콘테스트 플랫폼 이용자 약관</h1>
        <div>
          <p>
            본 이용약관("약관")은 귀하의 'GemPik' 비디오 콘테스트 앱
            플랫폼('GemPik') 사용에 적용됩니다.
          </p>
          <p>
            'GemPik'에 액세스하거나 사용함으로써 귀하는 본 약관에 구속된다는 데
            동의합니다.
          </p>
          <p>본 약관의 일부에 동의하지 않는 경우 앱을 사용해서는 안 됩니다.</p>
        </div>
      </header>
      <main className="gempik_terms_main">
        <div className="gempik_terms_main-title">
          <h2>1. 사용자 자격</h2>
          <p>
            ▪ 'GemPik'을 사용하려면 12세 이상이어야 합니다. 12세 미만인 경우
            앱을 사용하기 전에 부모 또는 보호자의 동의를 받아야 합니다.
            'GemPik'을 사용함으로써 귀하는 귀하가 연령 요건을 충족하고 필요한
            동의를 받았음을 진술하고 보증합니다.
          </p>
        </div>
        <div className="gempik_terms_main-title">
          <h2>2. 계정 생성 및 사용</h2>
          <p>
            ▪ 귀하는 'GemPik' 계정의 보안을 생성하고 유지할 책임이 있습니다.
            귀하는 등록 과정에서 정확하고 완전한 정보를 제공하는 데 동의합니다.
            귀하는 귀하의 계정 자격 증명을 공유하 거나 다른 사람이 귀하의 계정에
            액세스하도록 허용해서는 안 됩니다. 귀하의 계정에서 발 생하는 모든
            활동에 대한 책임은 전적으로 귀하에게 있습니다.
          </p>
        </div>
        <div className="gempik_terms_main-title">
          <h2>3. 사용자 콘텐츠</h2>

          <p>
            ▪ 'GemPik'을 사용하면 비디오 콘텐츠를 업로드, 제출 및 공유할 수
            있습니다. 콘텐츠를 제 출함으로써 귀하는 지적 재산권의 소유권을
            보유하지만 'GemPik'에 앱 운영 및 홍보 목적 으로 귀하의 콘텐츠를
            사용, 복제, 수정, 배포 및 표시할 수 있는 비독점적이고 전 세계적 이며
            로열티 없는 라이선스를 부여합니다.
          </p>
        </div>
        <div className="gempik_terms_main-title">
          <h2>4. 금지된 콘텐츠</h2>
          <p>
            ▪ 불법적이거나 유해하거나 공격적이거나 명예를 훼손하거나 타인의
            권리를 침해하는 콘텐츠 를 업로드하거나 제출해서는 안 됩니다.
            'GemPik'은 본 약관을 위반하거나 단독 재량으로 부적절하다고 간주되는
            콘텐츠를 제거할 권리를 보유합니다.
          </p>
        </div>
        <div className="gempik_terms_main-title">
          <h2>5. 지적 재산</h2>
          <p>
            ▪ 'GemPik' 및 관련 로고, 상표 및 기타 지적 재산은 'GemPik' 또는 해당
            라이선스 제공자 의 소유입니다. 사전 서면 허가 없이 'GemPik'의 지적
            재산을 사용할 수 없습니다.
          </p>
        </div>
        <div className="gempik_terms_main-title">
          <h2>6. 콘테스트 규칙</h2>
          <p>
            ▪ 'GemPik'은 사용자가 참여할 수 있는 비디오 콘테스트를 개최합니다.
            각 콘테스트에는 'GemPik'에서 설명하는 특정 규칙과 지침이 있습니다.
            콘테스트에 참여함으로써 귀하는 콘테스트 규칙 및 제공된 추가 조건을
            준수하는 데 동의합니다.
          </p>
        </div>
        <div className="gempik_terms_main-title">
          <h2>7. 사용자 행위</h2>
          <p>
            ▪ 합법적이고 존중하는 방식으로 'GemPik'을 사용해야 합니다. 귀하는
            앱의 작동 또는 다른 사용자의 경험을 방해하거나 방해하는 활동에
            관여해서는 안 됩니다. 여기에는 스팸, 해킹, 맬웨어 배포 또는 모든
            형태의 폭력적이거나 유해한 행동에 가담하는 것이 포함되며 이에
            국한되지 않습니다.
          </p>
        </div>
        <div className="gempik_terms_main-title">
          <h2>8. 프라이버시</h2>
          <p>
            ▪ 'GemPik'은 개인정보 보호정책에 설명된 대로 개인정보를 수집하고
            사용합니다. 앱을 사 용함으로써 귀하는 개인정보 보호정책에 설명된
            대로 귀하의 개인정보를 수집, 사용 및 공 개하는 데 동의합니다.
          </p>
        </div>
        <div className="gempik_terms_main-title">
          <h2>9. 책임의 제한</h2>
          <p>
            ▪ 'GemPik' 및 그 계열사는 귀하의 앱 또는 본 약관 사용과 관련하여
            발생하는 간접적, 부 수적, 특별 또는 결과적 손해에 대해 책임을 지지
            않습니다.
          </p>
        </div>
        <div className="gempik_terms_main-title">
          <h2>10. 수정 및 종료</h2>
          <p>
            ▪ 'GemPik'은 통지 유무에 관계없이 언제든지 앱 또는 본 약관을
            수정하거나 종료할 수 있 는 권리를 보유합니다. 귀하는 본 약관을
            주기적으로 검토할 것을 권장합니다. 'GemPik'은 앱 또는 본 약관의 수정
            또는 종료에 대해 귀하 또는 제3자에게 책임을 지지 않습니다.
          </p>
        </div>
        <div className="gempik_terms_main-title">
          <h2>11. 준거법</h2>
          <p>
            ▪ 본 약관은 [해당 관할권 삽입]의 법률에 따라 규율되고 해석됩니다. 본
            약관으로 인해 또는 이와 관련하여 발생하는 모든 법적 조치는 [해당
            관할권 삽입]의 법원에서만 독점적으로 제기됩니다.
          </p>
        </div>
        <div className="gempik_terms_main-title">
          <h2>12. 분리 가능성</h2>
          <p>
            ▪ 본 약관의 어떤 조항이 유효하지 않거나 시행할 수 없는 것으로
            판명되더라도 나머지 조항 은 완전한 효력을 유지합니다. 본 이용약관은
            귀하의 앱 사용에 관한 귀하와 'GemPik' 간의 전체 계약을 구성하며 이전
            의 모든 계약 또는 이해를 대체합니다.
          </p>
        </div>
      </main>
    </div>
  );
}
