import { Link } from "react-router-dom";
import "./Footer.css";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  return (
    <footer className="gempik_footer">
      <div className="gempik_footer_contents">
        <h3>(주)젬픽홀딩스</h3>

        <p>
          <span>Owner</span> : 김광호
        </p>
        <p>
          <span>Business License </span> : 201-88-04197
        </p>
        <p>
          <span>Address</span> : 서울시 용산구 한강대로40길 39-9, 비동
          비1층(한강로2가)
        </p>

        <p>
          <span>E-mail</span> : support@gempik.com
        </p>
        <p>
          <span>Call</span> : 02-797-4840
        </p>
      </div>
      <div className="gempik_footer_policy">
        <Link
          className="gempik_footer_policy_link"
          to="/privacy"
          onClick={scrollToTop}
        >
          개인정보처리방침
        </Link>
        <Link
          className="gempik_footer_policy_link"
          to="/terms"
          onClick={scrollToTop}
        >
          이용약관
        </Link>
      </div>
    </footer>
  );
}
