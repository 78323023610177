import "./Privacy.css";

export default function Privacy() {
  return (
    <div className="privacy-policy-container">
      <header className="privacy-policy-header">
        <h1>'GemPik' 콘테스트 플랫폼 개인 정보 보호 정책</h1>
        <div>
          <p>
            개인 정보 보호 정책은 귀하가 당사의 비디오 콘테스트 GemPik 플랫폼
            ('GemPik'이라고 함)을 사용할 때 개인 정보를 수집, 사용 및 공개하는
            방법을 설명합니다.
          </p>
          <p>
            'GemPik'을 사 용함으로써 귀하는 이 정책에 요약된 대로 귀하의 개인
            정보 수집 및 사용에 동의하는 것 입니다.
          </p>
        </div>
      </header>
      <main className="privacy-policy-main">
        <div className="privacy-policy-main-title">
          <h2>1. 수집하는 정보</h2>
          <p>
            ▪ 개인 정보 : 'GemPik'에서 계정을 만들 때 이름, 이메일 주소, 사용자
            이름, 프로필 사진 과 같은 특정 개인 정보를 수집합니다.
          </p>
          <p>
            ▪ 동영상 제출 : 'GemPik'에서 콘테스트에 참여하기 위해 제출한
            동영상을 수집합니다.
          </p>
          <p>
            ▪ 사용 정보 : 플랫폼, 콘테스트 및 기타 사용자와의 상호 작용을
            포함하여 'GemPik'을 사 용하는 방법에 대한 정보를 수집합니다.
          </p>
          <p>
            ▪ 장치 정보 : 장치 유형, 운영 체제 및 고유한 장치 식별자와 같은
            장치별 정보를 수집할 수 있습니다.
          </p>
        </div>
        <div className="privacy-policy-main-title">
          <h2>2. 정보 사용</h2>
          <p>
            ▪ 대회 참가 : 당사는 귀하가 제공한 개인 정보를 귀하의 비디오 대회
            참가를 촉진하고 대 회 관련 문제에 대해 귀하와 소통하기 위해
            사용합니다.
          </p>
          <p>
            ▪ 콘테스트 프로모션 : 귀하의 동의하에 귀하가 제출한 비디오 및 관련
            정보를 사용하여 콘테스트를 홍보하거나 수상자를 소개하거나 주목할
            만한 제출물을 강조할 수 있습니다.
          </p>
          <p>
            ▪ 플랫폼 개선 : 'GemPik'의 기능, 사용자 경험 및 콘텐츠 관련성을
            개선하기 위해 사용자 행동 및 피드백을 분석합니다.
          </p>
          <p>
            ▪ 통신 : 'GemPik' 및 콘테스트와 관련된 알림, 업데이트 및 마케팅
            메시지를 보낼 수 있 지만 언제든지 그러한 통신을 거부할 수 있습니다.
          </p>
          <p>
            ▪ 법적 준수 : 당사는 해당 법률, 규정, 법적 절차 또는 집행 가능한
            정부 요청을 준수하기 위해 귀하의 정보를 사용 및 공개할 수 있습니다.
          </p>
        </div>
        <div className="privacy-policy-main-title">
          <h2>3. 정보 공유</h2>
          <p>
            ▪ 공개 보기 : 귀하가 제출한 동영상 및 콘테스트 관련 정보는 콘테스트
            설정에 따라 다른 'GemPik' 사용자 및 일반 대중에게 표시될 수
            있습니다.
          </p>
          <p>
            ▪ 제3자 서비스 제공자 : 당사는 'GemPik'과 해당 콘테스트를 호스팅,
            관리 및 분석하는 데 도움을 주기 위해 제3자 서비스 제공자를 고용할 수
            있습니다. 이러한 공급자는 기밀 유지 계약에 구속되며 서비스를
            수행하는 데 필요한 정보에만 액세스할 수 있습니다.
          </p>
          <p>
            ▪ 사업 양도 : GemPik이 합병, 인수 또는 자산 매각을 진행하는 경우
            귀하의 정보가 새로 운 법인으로 이전될 수 있습니다.
          </p>
          <p>
            ▪ 동의 및 공유 기본 설정 : 당사는 귀하의 명시적인 동의 없이 마케팅
            목적으로 귀하의 개인 정보를 제3자와 공유하지 않습니다.
          </p>
        </div>
        <div className="privacy-policy-main-title">
          <h2>4. 데이터 보안</h2>
          <p>
            당사는 무단 액세스, 공개, 변경 또는 파괴로부터 귀하의 개인 정보를
            보호하기 위해 합당 한 조치를 취합니다. 그러나 인터넷이나 전자 저장을
            통한 데이터 전송 방법은 완전히 안 전하지 않습니다.
          </p>
        </div>
        <div className="privacy-policy-main-title">
          <h2>5. 아동의 개인정보 보호</h2>
          <p>
            이 'GemPik'은 12세 미만의 어린이를 대상으로 하지 않습니다. 부모의
            동의 없이 12세 미 만의 어린이로부터 개인 정보를 수집한 사실을 알게
            되면 서버에서 해당 정보를 제거하는 조치를 취할 것입니다.
          </p>
        </div>
        <div className="privacy-policy-main-title">
          <h2>6. 귀하의 권리 및 선택</h2>
          <p>
            귀하는 'GemPik'에 저장된 개인 정보에 액세스, 업데이트 및 삭제할
            권리가 있습니다. 'GemPik' 내에서 계정 설정 및 개인 정보 기본 설정을
            관리할 수 있습니다. 이러한 조치 에 대한 도움이 필요한 경우 지원 팀에
            문의하십시오.
          </p>
        </div>
        <div className="privacy-policy-main-title">
          <h2>7. 개인정보 보호정책 변경</h2>
          <p>
            당사는 관행 또는 법적 요구 사항의 변경 사항을 반영하기 위해 이 개인
            정보 보호 정책을 수시로 업데이트할 수 있습니다. 'GemPik' 또는 기타
            수단을 통해 중요한 변경 사항을 알 려드립니다. 이 정책을 주기적으로
            검토하는 것이 좋습니다. 이 개인 정보 보호 정책은 비디오 콘테스트
            'GemPik' 플랫폼에만 적용되며 'GemPik'에서 링크될 수 있는 제3자
            웹사이트, 서비스 또는 애플리케이션에는 적용되지 않습니다.
          </p>
        </div>
      </main>
    </div>
  );
}
